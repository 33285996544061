'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { categoriesList } from 'types/constants';
import { useEffect, useRef, useState } from 'react';
import ArrowUpIcon from 'components/UI/SvgIcons/ArrowUpIcon';
import ArrowDownIcon from 'components/UI/SvgIcons/ArrowDownIcon';

type Props = {
  children: React.ReactNode;
  closeMenu: React.MouseEventHandler;
};

const DropDownMenu = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: React.MouseEvent) => {
    setOpen((prev) => !prev);
    props.closeMenu(e);
  };

  const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('touchstart', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [open]);

  return (
    <div className="relative my-auto" ref={dropdownRef}>
      <button
        aria-label="Categories Menu"
        onClick={() => setOpen((prev) => !prev)}
        className="flex items-center justify-between w-full p-2 text-gray-100 rounded lg:p-0 hover:bg-gray-700 lg:w-max md:hover:bg-transparent hover:text-blue-300"
      >
        {props.children} {open ? <ArrowUpIcon className="w-5 h-5" /> : <ArrowDownIcon className="w-5 h-5" />}
      </button>
      <div
        className={clsx(
          open ? 'block' : 'hidden',
          'absolute right-0 z-10 font-normal bg-[#232323] divide-gray-600 rounded-lg shadow shadow-[#121212] w-48'
        )}
      >
        <ul className="py-2 text-sm :text-gray-400">
          {categoriesList.map((item) => (
            <Link
              key={item}
              onClick={handleClose}
              href={`/categories/${item.toLowerCase().replace(/ /g, '-')}`}
              className="block px-4 py-2 text-sm hover:text-gray-300 hover:bg-[#121212]"
            >
              {item}
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropDownMenu;
