import Image, { ImageProps } from 'next/image';

const CustomImage = ({ height, width, ...props }: ImageProps) => {
  const sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw';

  return (
    <Image
      {...props}
      width={width}
      quality={100}
      sizes={sizes}
      height={height}
      alt={props.alt}
      src={props.src}
      priority={props.priority}
      style={{ ...props.style }}
    />
  );
};

export default CustomImage;
