export const POSTS_PER_PAGE = 9;
export const FACE_CLAIMS_PER_PAGE = 9;

export const categoryRecords: Record<string, string> = {
  incest: 'Incest',
  domsub: 'DomSub',
  fiction: 'Fiction',
  'age-gap': 'Age Gap',
  interfaith: 'Interfaith',
  historical: 'Historical',
  'cheating-wife': 'Cheating Wife',
  'roleplay-prompt': 'Roleplay Prompt',
  'character-sketch': 'Character Sketch',
};

export const categoriesList: string[] = [
  'DomSub',
  'Incest',
  'Age Gap',
  'Fiction',
  'Interfaith',
  'Historical',
  'Cheating Wife',
  'Roleplay Prompt',
  'Character Sketch',
];
