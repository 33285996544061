import React from 'react';
import Link from 'next/link';

type Props = {
  path: string;
  children?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
};

const NavLink = ({ path, onClick, children }: Props) => {
  return (
    <Link
      href={path}
      onClick={onClick}
      className="block p-2 my-auto text-gray-100 rounded lg:w-max md:hover:bg-transparent hover:text-blue-300 lg:p-0 hover:bg-gray-700"
    >
      {children}
    </Link>
  );
};

export default NavLink;
