'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';
import NavLink from 'components/Layout/Navbar/NavLink';
import MenuIcon from 'components/UI/SvgIcons/MenuIcon';
import CrossIcon from 'components/UI/SvgIcons/CrossIcon';
import SearchBox from 'components/UI/SearchBox/SearchBox';
import RedditIcon from 'components/UI/SvgIcons/RedditIcon';
import DiscordIcon from 'components/UI/SvgIcons/DiscordIcon';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import DropDownMenu from 'components/UI/DropDownMenu/DropDownMenu';

const Navbar = () => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);

  return (
    <nav className="static bg-[#232323]" id="navbar">
      <div className="flex flex-col items-center justify-between p-4 mx-auto max-w-7xl lg:flex-row">
        <div className="flex flex-row items-center justify-between w-full">
          <Link href="/" passHref aria-label="Kinky Roleplay Plots Logo">
            <CustomImage
              priority
              width={50}
              height={50}
              loading="eager"
              src="/logo-100x100.png"
              alt="Kinky Roleplay Plots Logo"
              className="cursor-pointer h-auto aspect-[1/1]"
            />
          </Link>
          <div className="lg:hidden">
            <button aria-label="menu" onClick={() => setIsNavMenuOpen(!isNavMenuOpen)}>
              {isNavMenuOpen ? <CrossIcon /> : <MenuIcon />}
            </button>
          </div>
        </div>
        <div
          className={clsx('w-full p-2 flex-col lg:p-0 lg:flex-row lg:flex lg:gap-5', isNavMenuOpen ? 'flex' : 'hidden')}
        >
          <NavLink path="/" onClick={() => setIsNavMenuOpen(false)}>
            Blog
          </NavLink>
          <NavLink path="/#authors" onClick={() => setIsNavMenuOpen(false)}>
            Authors
          </NavLink>
          <NavLink path="/classifieds" onClick={() => setIsNavMenuOpen(false)}>
            Roleplayer Ads
          </NavLink>
          <NavLink path="/face-claims" onClick={() => setIsNavMenuOpen(false)}>
            Face Claims
          </NavLink>
          <DropDownMenu closeMenu={() => setIsNavMenuOpen(false)}>Categories</DropDownMenu>
          <a
            type="button"
            href="https://discord.gg/6GweWtyuUK"
            className="inline-flex items-center gap-2 p-2 text-gray-100 rounded md:hover:bg-transparent hover:text-blue-300 lg:p-0 hover:bg-gray-700"
          >
            <DiscordIcon className="animate-bounce hover:animate-none" />
            Server
          </a>
          <a
            type="button"
            href="https://reddit.com/r/dirtyRoleplayPlots/"
            className="inline-flex items-center gap-2 p-2 text-gray-100 rounded md:hover:bg-transparent hover:text-blue-300 lg:p-0 hover:bg-gray-700"
          >
            <RedditIcon className="animate-bounce hover:animate-none" />
            Subreddit
          </a>
          <SearchBox />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
