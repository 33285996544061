import SearchIcon from 'components/UI/SvgIcons/SearchIcon';

const SearchBox = () => {
  return (
    <div className="flex justify-start p-2 mt-2 lg:mt-0 lg:justify-end bg-[#121212] w-full lg:w-72 rounded-lg items-center">
      <form action="/search-results" method="GET" className="w-full">
        <label htmlFor="search" className="sr-only">
          Search Roleplay Plots
        </label>
        <div className="relative w-full">
          <button
            type="submit"
            aria-label="Search Roleplay Plots"
            className="absolute inset-y-0 left-0 flex items-center pl-1 cursor-pointer"
          >
            <SearchIcon aria-hidden="true" />
          </button>
          <input
            required
            id="search"
            name="search"
            autoComplete="off"
            aria-label="Search Roleplay Plots"
            placeholder="Search Roleplay Plots"
            className="bg-[#121212] focus:outline-none pl-8 w-full focus:text-gray-100 autofill:bg-[#121212]"
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
